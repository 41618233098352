
import { Options, Vue } from "vue-property-decorator";
import PageBlockComp from "@/components/pageblockcomp/index.vue";
import EchartComp from "@/components/echartcomp/index.vue";
import * as echart from "echarts";
import * as api from "@/api/environmentaltest";
import { parseTime } from "@/utils";
@Options({
  name: "HuanJingTongji",
  components: {
    PageBlockComp,
    EchartComp,
  },
})
export default class extends Vue {
  private optionMeter = {};
  private optionLine = {};
  private optionBar = {};
  private calendarShow = false;
  private numArr = [41.66, 83.83, 124.98, 166.64, 208.3, 249.96];
  devicePopup = false;
  private date = parseTime(new Date(), "{m}月{d}日 星期{a}");
  selectDate: Date = new Date();
  devicelist = [];
  selectdevice: any = {};
  propdata: any = {
    latest: {},
  };
  dom: any = ".page";
  show = true;
  get projectId() {
    return this.$store.state.project.projectinfo.projectId;
  }
  mounted() {
    this.getDeviceList();
  }
  async getDeviceList() {
    const res: any = await api.mobileenvdevicelist({
      projectId: this.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.devicelist = res.data;
    if (this.devicelist.length > 0) {
      this.selectdevice = res.data[0];
      this.getMobileenvitemlist();
    }
  }
  async getMobileenvitemlist() {
    const result: any = await api.mobileenvitemlist({
      projectId: this.projectId,
      createDate: parseTime(this.selectDate, "{y}-{m}-{d}"),
      deviceId: this.selectdevice.deviceId,
    });
    if (result.code !== 0) {
      return this.$toast.fail(result.msg);
    }
    this.propdata = result.data;
    console.log("propdata", this.propdata);
    this.initChart();
  }
  initChart() {
    let timearr: string[] = [],
      dataPM2: number[] = [],
      dataPM10: number[] = [],
      dataTSP: number[] = [],
      dataNoise: number[] = [];
    if (this.propdata.items && this.propdata.items.length > 0) {
      this.propdata.items.map((it: any) => {
        timearr.push(it.createDate.substr(11, 5));
        dataPM2.push(it.pm25);
        dataPM10.push(it.pm10);
        dataTSP.push(it.tsp);
        dataNoise.push(it.noise);
      });
    }
    this.optionLine = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        show: true,
        bottom: "2%",
        icon: "rect",
        itemHeight: 14,
        itemWidth: 14,
        itemGap: 30,
      },
      grid: {
        top: "10%",
        left: "5%",
        right: "5%",
        bottom: "15%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#C7CBDF",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          align: "center",
          color: "#000",
        },
        axisPointer: {},
        // data: timearr,
        data: timearr,
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: "#000",
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          name: "PM2.5",
          type: "line",
          lineStyle: {
            color: "#F1B010",
            width: 2,
          },
          itemStyle: {
            color: "#F1B010",
          },
          data: dataPM2,
        },
        {
          name: "PM10",
          type: "line",
          lineStyle: {
            color: "#3F7CFF",
            width: 2,
          },
          itemStyle: {
            color: "#3F7CFF",
          },
          data: dataPM10,
        },
        {
          name: "TSP",
          type: "line",
          // smooth: true,
          lineStyle: {
            color: "#00B886",
            width: 2,
          },
          // symbol: "none",
          itemStyle: {
            color: "#00B886",
          },
          data: dataTSP,
        },
      ],
    };
    this.optionBar = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        show: false,
      },
      grid: {
        top: "10%",
        left: "5%",
        right: "5%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#C7CBDF",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          align: "center",
          color: "#000",
        },
        axisPointer: {},
        data: timearr,
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: "#000",
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "bar",
          barWidth: 13,
          itemStyle: {
            color: new echart.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 1, color: "#FFC3A6" },
              { offset: 0, color: "#F79060" },
            ]),
          },
          data: dataNoise,
        },
      ],
    };
    this.optionMeter = {
      series: [
        {
          type: "gauge",
          min: 0,
          max: 250,
          // splitNumber: 6,
          radius: 48,
          center: ["50%", "50%"],
          startAngle: 225,
          endAngle: -45,
          axisLine: {
            show: true,
            lineStyle: {
              width: 10,
              color: [
                [
                  0.167,
                  new echart.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#56AC14", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#56AC14", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],

                [
                  0.334,
                  new echart.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "#FDBD34", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FDBD34", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],
                [
                  0.501,
                  new echart.graphic.LinearGradient(
                    1,
                    0,
                    0,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#FF983C", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#FF983C", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],
                [
                  0.668,
                  new echart.graphic.LinearGradient(
                    1,
                    0,
                    0,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#F34D31", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#F34D31", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],
                [
                  0.835,
                  new echart.graphic.LinearGradient(
                    1,
                    0,
                    0,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#9B2CCD", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#9B2CCD", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],
                [
                  1,
                  new echart.graphic.LinearGradient(
                    1,
                    0,
                    0,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#830D0D", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#830D0D", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],
              ],
              // borderColor:'#000',
              borderWidth: "10",
            },
          },
          axisLabel: {
            show: false,
          }, //刻度标签。
          axisTick: {
            show: false,
          }, //刻度样式
          splitLine: {
            show: false,
          },
          detail: {
            show: true,
            formatter: function () {
              return "AQI";
            },
            offsetCenter: [0, "-35%"],
            color: "#8893A3",
            fontSize: 12,
          },
          data: [
            {
              value: this.propdata.latest ? this.propdata.latest.pm25 : 0,
            },
          ],
        },
        {
          type: "gauge",
          min: 0,
          max: 100,
          splitNumber: 6,
          radius: 36,
          center: ["50%", "50%"],
          startAngle: 225,
          endAngle: -45,
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: [
                [
                  1,
                  new echart.graphic.LinearGradient(
                    1,
                    0,
                    0,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#E9E9EA", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#E9E9EA", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                ],
              ],
              // borderColor:'#000',
              borderWidth: "10",
            },
          },
          axisLabel: {
            show: false,
          }, //刻度标签。
          axisTick: {
            show: false,
          }, //刻度样式
          splitLine: {
            show: true,
            distance: 0,
            length: 4,
            lineStyle: {
              color: "#E9E9EA",
              width: 2,
            },
          },
          detail: {
            show: false,
          },
        },
      ],
    };
    (this as any).$refs.linechart.setOption(this.optionLine);
    (this as any).$refs.barchart.setOption(this.optionBar);
    (this as any).$refs.meterchart.setOption(this.optionMeter);
  }
  fConfirmDate(date: Date) {
    this.selectDate = date;
    this.date = parseTime(date, "{m}月{d}日 星期{a}");
    this.calendarShow = false;
    this.getMobileenvitemlist();
  }
  fSelectDevice(arg: any) {
    this.selectdevice = arg;
    this.devicePopup = false;
    this.getMobileenvitemlist();
  }
  fOpenCalendar() {
    this.calendarShow = true;
  }
}
